import React from 'react';

import publishers from '../data/_publishers';

const Footer = ({lang}) => (
    <div className="footer ornamented">
        <div className="footer-left" />
        <div className="footer-navigation">
            <ul className="footer-navigation-list">
                {publishers.map(({label, path}, i) => (
                    <li key={i} className="footer-navigation-list-item">
                        <a href={path} target="_blank" rel="noopener noreferrer">{label}</a>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

export default Footer;
