import React from 'react';
import {Link} from 'gatsby';

const ArticleMini = ({article, lang, slug}) => {
    const {authors, doi, id, title, title_en} = article.frontmatter;

    const url = `${lang}/articles/${id || doi.replace('10.23817/lingtreff.', '')}`;

    return (
        <div>
            {authors.map(({firstName, lastName}, i, arr) => (
                <span key={`${slug}-authors-${i}`}>
                    {`${firstName} ${lastName}${(i < arr.length - 1) ? ', ' : ''}`}
                </span>
            ))}
            <span>
                , <Link to={url}>{title}{
                    title_en
                    ? (<span> / {title_en}</span>)
                    : null
                }</Link>
                {
                    doi
                    ? <span> (DOI: <a href={`https://doi.org/${doi}`}>{doi}</a>)</span>
                    : null
                }
            </span>
        </div>
    );
};

export default ArticleMini;
