import React from 'react';
import * as JsSearch from 'js-search';
import 'url-search-params-polyfill';

import ArticleMini from '../components/ArticleMini';
import Divider from '../components/Divider';
import Layout from '../components/Layout';
import dictionary from '../data/_dictionary';

const SearchWithParams = props => {
    const {description, from, keywords, lang, titleDictionaryIndex, articles, title, url} = props.pageContext;
    const params = new URLSearchParams(props.location.search.substr(1));
    const query = params.get('query');
    const searchTitle = dictionary[titleDictionaryIndex][lang].replace('*', query);
    const noResults = dictionary.noResults[lang];

    const search = new JsSearch.Search('id');

    switch (props.mode) {
        case 'keyword':
            search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
            search.addIndex(['frontmatter', 'keywords', lang]);
            break;
        case 'author':
            search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
            search.addIndex(['authorDenormalized']);
            break;
        default:
            search.indexStrategy = new JsSearch.ExactWordIndexStrategy();
            search.addIndex(['frontmatter', 'title']);
            search.addIndex(['frontmatter', 'title_en']);
            search.addIndex(['frontmatter', 'keywords', lang]);
            search.addIndex(['frontmatter', 'item_abstracts', lang]);
            search.addIndex(['frontmatter', 'item_abstracts', 'en']);
            search.addIndex('authorDenormalized');
            // search.addIndex('rawMarkdownBody');
    }

    search.addDocuments(articles);

    const articlesFiltered = search.search(query);

    return (
        <Layout
            description={description}
            keywords={keywords}
            lang={lang}
            name='search'
            path={from}
            title={title}
            url={url}
        >
            <h2>{searchTitle}</h2>
            <Divider weak />
            {articlesFiltered.length ? (
                <ul>
                    {articlesFiltered.map(article =>
                        <li key={article.fields.slug}>
                            <ArticleMini article={article} lang={lang} slug={article.fields.slug} />
                        </li>
                    )}
                </ul>
            ) : (
                <p>{noResults}.</p>
            )}
        </Layout>
    );
};

export default SearchWithParams;
