import React from 'react';
import {Helmet} from 'react-helmet';

import favicon from '../images/favicon.ico';

const Meta = ({
    description = {en: '', de: '', pl: ''},
    keywords = {en: '', de: '', pl: ''},
    lang = 'en',
    name,
    path,
    title,
    url,
}) => (
    <Helmet
        title={name ? `${title} - ${name}` : title}
        meta={[
            {name: 'description', content: description[lang]},
            {name: 'keywords', content: keywords[lang]},
            {name: 'path', content: path ? `${url}${path}` : url}
        ]}
        link={[
            {rel: 'shortcut icon', type: 'image/ico', href: `${favicon}`}
        ]}
    >
        <html lang={lang} />
        {/*<link href='https://fonts.googleapis.com/css?family=Source+Sans+Pro' rel='stylesheet' />*/}
        {/*<link href='https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300' rel='stylesheet' />*/}
    </Helmet>
);

export default Meta;
